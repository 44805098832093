.main-box{
  // background-color: #EDEDED;
  background: url('../../images/webp/auth-bg.webp');
  background-size: cover;
  .inner-box{
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    // background: #fff;
    position: relative;
    @include breakpoint('md') {
      width: 50%;
    }
    &.pattern {
      // background: url('../../images/auth-bg.webp');
      // background-size: cover;
    }
    .auth-step{
      .ReactInputVerificationCode__item{
        font-weight: 500;
        border: 1px solid $borderFieldColor;
        box-shadow: 0px 5px 80px rgb(148 182 223 / 15%) !important;
        transition: border 0.2s ease-out;
        border-radius: $border-radius;
        &.is-active{
          border: 1px solid $primary;
        }
      }
    }
    .hd-logo{
      width: 370px;
      height: 250px;
      background: url('../../images/image/hd-logo.png') no-repeat center center / 90%;
      position: absolute;
      top: 200px;
    }
    .copyright-text{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}