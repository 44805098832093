body{
  background-color: $lightGray2;
  color: $black;
  font-size: 12px;
  @include breakpoint('sm') {
    font-size: 14px;
  }
}
img{ max-width: 100%; }

.rtl{ direction: rtl }
.ltr{ direction: ltr }

.font-9{ font-size: 9px !important }
.font-10{ font-size: 10px !important }
.font-11{ font-size: 11px !important }
.font-12{ font-size: 12px !important }
.font-13{ font-size: 13px !important }
.font-14{ font-size: 14px !important }
.font-15{ font-size: 15px !important }
.font-16{ font-size: 16px !important }
.font-18{ font-size: 18px !important }
.font-20{ font-size: 20px !important }
.font-22{ font-size: 22px !important }
.font-24{ font-size: 24px !important }
.font-25{ font-size: 25px !important }
.font-27{ font-size: 27px !important }
.font-30{ font-size: 30px !important }
.font-34{ font-size: 34px !important }

.font-weight-medium{ font-weight: 500 !important; }
.font-weight-semibold{ font-weight: 600 !important; }
.font-weight-900{ font-weight: 900 !important; }
.font-weight-700{ font-weight: 700 !important; }


//.font-pelak{ font-family: 'Pelak', Arial, sans-serif }
.font-vazir{ font-family: 'Vazir', Arial, sans-serif }
.font-en{ font-family: 'Gilroy', Geneva, Verdana, sans-serif }
.font-serif{ font-family: sans-serif }

.color-blue{ color: $blue }
.text-blue-update{ color: $blueUpdate }
.text-purple{ color: $purple }
.text-red { color: $red }
.text-danger { color: $red }
.text-yellow { color: $yellow }
.text-orange { color: $orange }
.color-light-gray{ color: $lightGray }
.text-white{ color: #fff }
.text-gray{ color: $lightGray }
.text-gray2{ color: $gray2 }
.text-gray3{ color: $gray3 }
.text-green{ color: $green }
.text-green2{ color: $green2 }
.text-black{ color: $dark }
.text-primary{color:$primary}
.text-pre-wrap { white-space: pre-wrap; }
.text-primry-light{
  color:$primary-light
}
.bg-blue-update{ background-color: $lightBlueUpdate }
.bg-purple{ background-color: $lightPurple }
.bg-gray{ background-color: $lighterGray; }
.bg-gray3{ background-color: $gray3; }
.bg-gray4{ background-color: $gray4; }
.bg-gray5{background-color: $gray5}
.bg-gray-disabled { background-color: $gray-disabled }
.bg-input-disabled { background-color: $input-disabled }
.bg-video-call-button-gray{ background-color: $videoCallButtonGray; }
.bg-green{ background-color: $green; }
.bg-light-green-success{ background-color: $lightGreenSuccess; }
.bg-light-green{ background-color: $lightGreen; }
.bg-yellow1{ background-color: $yellow1; }
.bg-dark{ background-color: $dark; }
.bg-video-call-button-black{
  background-color: $videoCallButtonBlack;
  &:hover {
    background-color: lighten($videoCallButtonBlack, 10%);
  }
}
.bg-light-dark{ background-color: $lightDark !important; }
.bg-lighter-dark{ background-color: $lighterDark !important; }
.bg-light-red{ background-color: $lightRed; }
.bg-video-call-button-red{ background-color: $videoCallButtonRed; }
.bg-primary{ background-color: $primary; }
.bg-primary-10 { background-color: $primary-10 }
.bg-danger-light {background-color: $danger-light;}
.background-gray-hover {
  transition: background-color 0.3s;
  &:hover{ background-color: $lightGray2; }
}
.bg-red-hover{
  background-color: $lightRed;
  color: $red;
  transition: background-color 0.3s;
  &:hover{
    background-color: darken($lightRed, 3%);
  }
}

.w-20{ width: 20px; }
.w-70{ width: 70px; }
.w-80{ width: 80px; }
.w-110{ width: 110px; }
.w-600{ width: 600px; }
.h-110{ height: 110px; }
.h-40{ height: 20px; }
.h-121{ height: 121px; }
.h-500{ height: 500px; }
.h-90{ height: 90%; }

.minw-22{ min-width: 22px }
.minw-120{ min-width: 120px }
.minw-400{ min-width: 400px }
.minw-460{ min-width: 460px }

.size-15{ @include iSize(15px); min-width: 15px }
.size-30{ @include iSize(30px); min-width: 30px }
.size-40{ @include iSize(40px); min-width: 40px }
.size-45{ @include iSize(45px); min-width: 45px }
.size-47{ @include iSize(47px); min-width: 47px }
.size-50{ @include iSize(50px); min-width: 50px }
.size-100{ @include iSize(100px); min-width: 100px }
.size-110{ @include iSize(110px); min-width: 110px }
.size-200{ @include iSize(200px); min-width: 200px }
.size-a4{
  width: 793px;
  height: 1122px;
}

.line-18{ line-height: 1.8 }
.cur-pointer{ cursor: pointer }
.cur-help{ cursor: help }
.cur-move{ cursor: move }
.cur-grab{ cursor: grab }
.border-gray{ border: 1px solid #E0E0E0 }
.border-green2{ border: 1px solid $green2 }
.border-green-success{ border: 1px solid $greenSuccess }
.border-red{ border: 1px solid $red }
.border-orange{ border: 1px solid $orange }
.border-light-blue{ border-color: rgba(46, 87, 255, 0.1) !important; }
.radius-2{ border-radius: 2px !important }
.box-shadow{ box-shadow: 0px 0px 10px rgba(168, 191, 224, 0.35); }
.box-shadow-0{ box-shadow: none !important }
.pos-rel{ position: relative }
.pe-none{ pointer-events: none }
.object-fit-cover{ object-fit: cover; }

.pl-6, .px-6 {
  padding-left: 4.5rem !important;
}

.line-height-2 { line-height: 2 !important }

.text-link{
  color: $primary;
  cursor: pointer;
  &:hover{ color: darken($primary, 20%) }
}
.text-hover{
  &:hover{ color: $primary }
}
.text-red-hover{
  &:hover{ color: $red }
}
.text-green2-hover{
  &:hover{ color: $green2 }
}
.text-black-hover{
  &:hover{ color: $black }
}

.card-box{
  padding: 20px 15px;
  background-color: #FFF;
  border-radius: $borderRadius;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  position: relative;
  @include breakpoint('sm') {
    padding: 30px 25px;
  }
  &.rounded-sm-0 {
    border-radius: 0;
    @include breakpoint('sm') {
      border-radius: $borderRadius;
    }
  }
}
.card-box-gray{
  padding: 20px 15px;
  background-color: #f0f2f5;
  border-radius: $borderRadius;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  position: relative;
  @include breakpoint('sm') {
    padding: 30px 25px;
  }
  &.rounded-sm-0 {
    border-radius: 0;
    @include breakpoint('sm') {
      border-radius: $borderRadius;
    }
  }
}

.dashboard-card-box{
  padding: 10px 10px;
  background-color: #FFF;
  border-radius: $borderRadius;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;
}

.shared-box {
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  @include breakpoint('sm') {
    margin: 20px auto;
  }
}

.w-30{ width: 30px }
.w-40{ min-width: 40% }
.w-51{ width: 51px }
.w-90{ width: 90px }
.w-200{ width: 200px }
.w-350{ width: 350px }
.w-400{ width: 400px }
.w-500{ width: 500px }
.w-800{ width: 800px }
.w-1000{ width: 1000px }
.w-1300{width:1300px}
@include breakpoint('xl') {
  .w-xl-auto {
    width: auto !important;
  }
}
.border-none{ border-color: transparent }

.opacity-25 { opacity: .25 }
.opacity-65 { opacity: .65 }

.bg-opacity-5 { --bs-bg-opacity: 0.05; }



.p-xy{
  padding: 20px 15px;
  @include breakpoint('sm') {
    padding: 30px 25px;
  }
}

.mx-n1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.mx-n2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n5 {
  margin-left: -3.125rem !important;
  margin-right: -3.125rem !important;
}

.me-n1 {

  margin-right: -0.25rem !important;
}

.me-n2 {
    margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
    margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3.125rem !important;
}

.zIndex-1 { z-index: 1 }
.zIndex-2 { z-index: 2 }
.zIndex-1055 { z-index: 1055 !important }

.word-spacing-10{ word-spacing: 10px; }

.box-light{
  background-color: lighten($gray4, 3%);
  transition: all .2s ease;
  &:hover{
    background-color: $gray4;
  }
}

.btn{
  padding: 8px 30px;
  font-size: 12px;
  color: #FFF;
  border-radius: $borderRadius;
  &:focus{ box-shadow: none }
  &:hover{ color: #FFF }
  &.btn-outline-primary{
    color: $primary;
    border: 1px solid $primary;
    &:hover{ background-color: $lightPrimary }
    &:active{ color: $primary !important }
  }
  &.btn-danger{
    background-color: $red;
    &:hover{ background-color: darken($red, 8%); }
  }
  &.btn-blue{ 
    background-color: $blue;
    &:hover{ background-color: darken($blue, 8%); }
  }
  &.btn-light-blue{
    color: $primary;
    background-color: #EAEEFF;
    &:hover{ background-color: darken(#EAEEFF, 5%); }
  }
  &.btn-outline-blue{
    color: $blue;
    border: 1px solid $blue;
    &:hover{ background-color: lighten($blue, 30%); }
  }
  &.btn-orange{ 
    background-color: $orange;
    &:hover{ background-color: darken($orange, 8%); }
  }
  &.btn-outline-orange{
    color: $orange;
    border: 1px solid $orange;
    &:hover{ background-color: lighten($orange, 30%); }
  }
  &.btn-dark{ 
    background-color: $dark;
    &:hover{ background-color: lighten($dark, 15%); }
  }
  &.btn-outline-dark{
    color: $black;
    border: 1px solid $black;
    &:hover{
      color: #FFF;
      background-color: lighten($black, 30%);
    }
  }
  &.btn-large{
    padding: 12px;
    font-size: 16px;
  }
  &.btn-gray{
    background-color: #E0E0E0;
    color: $black;
    &:hover{ background-color: darken(#E0E0E0, 8%); }
  }
  &.btn-gray-disabled{
    background-color: #f3f3f4;
    color: $black;
    border:none;
  }
  &.btn-white{
    color: $black;
    background-color: #FFF;
    transition: background .2s ease-in;
    &:hover{ background-color: #F1F1F1 }
  }
  &.btn-green{
    background-color: #CEF5E4;
    color: $green2;
    &:hover{
      color: $white;
      background-color: #09CB7A;
    }
  }
  &.btn-green-dashboard{
    background-color: #65D9D4;
    color: $white;
    &:hover{
      color: $white;
      background-color: darken(#65D9D4, 15%);
    }
  }
  &.btn-red{
    background-color: #FFDDDD;
    color: $red;
    &:hover{
      color: $white;
      background-color: #FF5656;
    }
  }
  &.btn-outline-white{
    color: $black;
    background-color: #FFF;
    border: 1px solid $gray4;
    transition: background .2s ease-in;
    &:hover{ background-color: #F1F1F1 }
  }
  &.btn-outline-gray{
    border: 1px solid #E0E0E0;
    color: #757575;
    &:hover{ background-color: $lighterGray; }
  }
  &.btn-outline-red{
    color: $red;
    border: 1px solid $red;
    &:hover{ background-color: lighten($red, 30%); }
  }
  &.btn-navy-blue{
    color: $white;
    background-color: $navyBlue;
    border: 1px solid $navyBlue;
    &:hover{ background-color: darken($navyBlue, 30%); }
  }
  &.btn-video-call{
    &:hover{ background-color: lighten($videoCallButtonGray, 10% ) }
  }
}

.btn-link-custom{
  padding: 0;
  border: none;
  background: none;
  &.btn-link-blue{
    color: $blue;
    &:hover{ color: darken($blue, 8%); }
  }
}

.zIndex-1 { z-index: 1 }

.alert-warning{
  background-color: $lightOrange;
  color: $orange;
}
.alert-danger{
  background-color: $lightRed;
  color: $red;
}
.alert-success{
  background-color: $green2;
  color: $white;
}

.file-uploader-box{
  position: relative;
  overflow: hidden;
  .file-uploader{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 200px;
    text-indent: 100%;
    outline: none;
    box-shadow: none;
    cursor: pointer;
  }
}

.box-scroll{
  overflow-y: scroll;
  overflow-x: auto;
}
.h-100v{ height: calc(100vh - #{$headerHeight}) }
.h-47{ height: 47px }
.h-150{ height: 150px }

.text-overflow{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box-loader{
  // background: url('../images/line-loader.gif') no-repeat center center;
  &.cover-mode{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(237, 237, 237, .3);
    z-index: 5;
  }
  &.text-loader{
    display: inline-block;
    width: 15px;
    height: 12px;
    background: url('../images/text-loader.gif') no-repeat center center;
  }
}
.list-loader{
  padding: 18px;
  text-align: center;
  background: url('../images/line-loader.gif') no-repeat center center;
}

.dot-loader{
  text-align: center;
  background: url('../images/line-loader.gif') no-repeat center center;
}

.tooltip-pre-line {
  white-space: pre-line;
  line-height: 1;
  font-size: 12px;
  font-family: 'Vazir';
}


.divider{
  height: 1px;
  min-height: 1px;
  background: rgb(211,211,212);
  background: linear-gradient(90deg, rgba(211,211,212,0) 0%, rgba(211,211,212,1) 50%, rgba(211,211,212,0) 100%);
}

.plc-right{
  &::placeholder{
    text-align: #{$startDir};
  }
}

button[disabled], button[disabled]:hover {
  color: #aaa;
  cursor: not-allowed;
  background-color: #ebebeb;
}
.btn.btn-outline-primary:active{
  color: white !important;
}

.highlight {
  background-color: #B9EFD2;
  font-weight: bold;
}

.ehr-nav {
  .nav-pills,
  .nav-link.active{
    color: var(--bs-nav-pills-link-active-color);
    background-color: black;
  }
  .nav-link {
    color: #454855;
    padding: 15px 10px;
  }
}

.summary-nav {
  .nav-pills,
  .nav-link.active{
    color: var(--bs-nav-pills-link-active-color);
    background-color: black;
    border-bottom: 25px;
  }

  .nav-link {
    color: #454855;
    padding: 15px 10px;
  }
}

.vital-list-chart-nav {
  border: 0.5px solid $primary;
  border-radius: 0.5rem;

  .nav-pills,
  .nav-link.active{
    color: var(--bs-nav-pills-link-active-color);
    background-color: black;
    border-radius: 0.5rem;
  }
  .nav-link {
    color: $primary;
    padding: 7px 7px;
  }
}

.vital-chart-nav {
  .nav-link.active{
    color: var(--bs-nav-link-color);
    border-right: 2px solid $primary;
  }
  .nav-link {
    color: $gray2;
    padding: 10px 10px;
    border-bottom: none;
    border-right: 2px solid $gray3;
  }
}

.thumbnail {
  background-color: $gray4;
  width: 181px;
  height: 121px;
  display: inline-block; /* makes it fit in like an <img> */
  background-size: cover; /* or contain */
  background-position: center center;
  background-repeat: no-repeat;
}

.summary-thumbnail {
  background-color: $gray4;
  width: 106px;
  height: 71px;
  display: inline-block; /* makes it fit in like an <img> */
  background-size: cover; /* or contain */
  background-position: center center;
  background-repeat: no-repeat;
}

.custom-tooltip > .tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #fff;
  box-shadow: 0px 0px 10px rgba(168, 191, 224, 0.35);
}

.custom-tooltip > .tooltip-arrow {
  background-color: #fff;
  color: #fff;
}

// table
.border-rounded{
  // border-radius: 12px;
  // border:1px solid $gray2;

  border-collapse: collapse;
  border-radius: 12px;
  border-style: hidden;
  box-shadow: 0 0 0 1px $gray4;
  tr td {
    padding: 16px 0px !important;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: $dark;
}
tr:nth-child(odd){
  div{
    background: white;
    padding:5px
  }
}
  tr td:first-child div{
   margin-right: 1rem;
  text-align: center;
}
  tr td:last-child div{
  margin-left: 1rem;
 text-align: center;
}
}
.button-sizing{

  @media (max-width:1440px)  {
    font-size: 8px !important;
  }
}

.remove-carousel-arrows {
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
}

.border-hover-red {
  border: 2px solid $primary; /* Default border color (transparent) */
  transition: border-color 0.3s ease; /* Smooth transition for the border color change */
  &:hover {
    border-color: red;
  }
}

/* Ensure the container takes 100% width unless there's a scrollbar */
.w-responsive {
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}

/* Allow the content to shrink when it overflows */
.w-responsive.auto-width {
  width: auto;
}

.notification-show-animation {
  animation: openSpring 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
}

@keyframes openSpring {
  from {
    transform: translateY(-100px) translateX(20px);
  }
  to {
    transform: translateY(20px) translateX(20px);
  }
}

.notification-close-animation {
  animation: closeSpring 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
}

@keyframes closeSpring {
  from {
    transform: translateY(20px) translateX(20px);
  }
  to {
    transform: translateY(-100px) translateX(20px);
  }
}
