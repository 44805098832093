.prof-bg{
  height: 140px;
  margin: -30px -25px;
  border-radius: $borderRadius;
  background: url('../../images/image/account-bg.png') no-repeat center center / cover;
}

.prof-form-cont{
  margin: -90px 10px 0;
  @include breakpoint('md') {
    margin: -45px 25px 0;
  }
}

.prof-icon{
  position: absolute;
  top: -30px;
  #{$endDir}: 18px;
  @include breakpoint('xl') {
    top: -18px;
  }
}

.acc-box{
  height: 200px;
  // &:nth-child(even){
  //   .acc-inner-box{
  //     filter: grayscale(1);
  //   }
  // }
  .acc-inner-box{
    width: 130px;
    height: 100px;
    position: relative;
    left: -24px;
    background: $gray4 url('../../images/image/profile-pattern.png') no-repeat left center / contain;
  }
}