// Dependencies
@import 'variables';

$startDir: right;
$endDir: left;

@import 'bootstrap';
@import 'fonticon';
@import 'fontFA';
@import 'fontEN';
@import 'mixin';
@import 'media';
@import 'general';
@import 'reset';

// Global Styles
@import 'main';

// Components
@import './components/general';
@import './components/header';
@import './components/sidebar';
@import './components/table';
@import './components/form';
@import './components/advancedSearch';
@import './components/advancedSampleReport';
@import './components/videoCall';
@import './components/dashboard';
@import './components/visit';

// Pages
@import './pages/externalPages';
@import './pages/internalPages';
@import './pages/profile';
@import './pages/notFound';




body{
  font-family: 'Vazir', Arial, sans-serif;
  text-align: right;
}