body{
  &.sidebar-collapsed{
    .main-container{
      .main-content{
        // filter: blur(0);
        @include breakpoint('md') {
          width: calc(100% - #{$sidebarCollapsedWidth});
          margin-#{$startDir}: $sidebarCollapsedWidth;
        }
      }
    }
  }
}

.main-container{
  display: flex;
  min-height: 100vh;
  .main-content{
    width: 100%;
    margin-top: $headerHeight;
    padding-bottom: 60px;
    position: relative;
    transition: all .2s $collapsedTransition;
    // filter: blur(6px);
    @include breakpoint('sm') {
      padding: 20px 20px 60px 20px;
    }
    @include breakpoint('md') {
      width: calc(100% - #{$sidebarWidth});
      margin-#{$startDir}: $sidebarWidth;
      // filter: blur(0);
    }
  }
}