
$headerHeight: 105px;
$sidebarWidth: 340px;
$chatWidth: 450px;
$sidebarCollapsedWidth: 137px;
$chatCollapsedWidth: 0px;
$borderRadius: 12px;
$collapsedTransition: cubic-bezier(0.4, 0, 0.6, 1) 0ms;

// colors
$borderFieldColor: #dfdfe0;
$placeholderFieldColor: #909090;
$readOnlyFieldColor: #BCBCBC;

$primary: #2e57ff;
$primary-10: #EAEEFF;
$lightPrimary: #ebf4ff;
$dark: #1f1f2f;
$lightDark: #2B2B2D;
$lighterDark: #3F3E43;
$black: #676781;
$videoCallButtonBlack: #3C4043;
$green: #00BAC9;
$green2: #30CB81;
$lightGreen: #ECF8F7;
$greenSuccess: #2FFFA4;
$lightGreenSuccess: #C1FFE4;
$blue: #518EF8;
$navyBlue: #00317A;
$lightBlue: #E3F2FD;
$blueUpdate: #3290FF ;
$lightBlueUpdate: #EBF4FF ;
$purple: #7239EA;
$lightPurple: #F8F5FF;
$red: #F0506D;
$lightRed: #FFEBEE;
$videoCallButtonRed: #EA4233;
$yellow: #FFC225;
$yellow1: #FCF0D1;
$orange: #FFAA05;
$lightOrange: #F9F4E3;
$gray: #757575;
$gray2: #8c8c9f;
$gray3: #d3d3d4;
$gray4: #eef1f5;
$gray5:#2E57FF0D;
$gray-disabled: #ECEDEE;
$input-disabled: #E9ECEF;
$lightGray: #9C9C9C;
$lightGray2: #f0f2f5;
$lighterGray: #F5F5F5;
$videoCallButtonGray: #616161;
$black-10: #EAEAED;
$black-0: #434363;
$danger-light:#ffcdcd;
$primary-light:#ccd6ff;

// reset bootstrap variables
$theme-colors: (
  "primary": $primary,
  // "secondary":  $secondary,
  "success":  $green,
  // "info":       $info,
  "warning":  $yellow,
  "danger": $red,
  "light": $black-10,
  "dark": $black,
  "dark-0": $black-0
);
$colors: (
  "blue": $blue,
  // "purple": $purple,
  "red":  $red,
  "yellow": $yellow,
  "green":  $green,
  "black":  $black,
  // "white":      $white,
  // "gray": $gray-50,
  // "gray-dark":  $gray-800
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 12,
);

$position-values: (
  0: 0,
  5: 5%,
  50: 50%,
  75: 75%,
  90: 90%,
  100: 100%
);

:root{
  --ReactInputVerificationCode-itemWidth: 55px !important;
  --ReactInputVerificationCode-itemHeight: 55px !important;
  --ReactInputVerificationCode-itemSpacing: 7px !important;
}

// reset bootstrap variables
$theme-colors: (
  "primary": $primary
);
$border-radius: $borderRadius;
// $btn-focus-box-shadow: 'none' !default;

// font icons
$icomoon-font-family: "SaaIran-FontIcon" !default;
$icomoon-font-path: "../fonts/fonticon" !default;

$icon-clipboard-check-fill: unquote('"\\e916"');
$icon-confounded-square: unquote('"\\e919"');
$icon-facemask: unquote('"\\e902"');
$icon-document-add-fill: unquote('"\\e91a"');
$icon-chevron-up-circle-fill: unquote('"\\e915"');
$icon-sad: unquote('"\\e90a"');
$icon-user-circle: unquote('"\\e90f"');
$icon-folder-open: unquote('"\\e900"');
$icon-add-square: unquote('"\\e90b"');
$icon-smoke: unquote('"\\e934"');
$icon-pills: unquote('"\\e92f"');
$icon-test: unquote('"\\e937"');
$icon-dropper: unquote('"\\e91d"');
$icon-gallery: unquote('"\\e925"');
$icon-gallery-edit: unquote('"\\e924"');
$icon-users-group-fill: unquote('"\\e939"');
$icon-chat-fill: unquote('"\\e91f"');
$icon-chat: unquote('"\\e910"');
$icon-star-fill: unquote('"\\e935"');
$icon-star: unquote('"\\e936"');
$icon-calendar: unquote('"\\e90e"');
$icon-archive-down: unquote('"\\e90c"');
$icon-arrow-right: unquote('"\\e90d"');
$icon-check-square: unquote('"\\e911"');
$icon-chevron-down-circle: unquote('"\\e912"');
$icon-chevron-down: unquote('"\\e913"');
$icon-chevron-right: unquote('"\\e914"');
$icon-close-square: unquote('"\\e917"');
$icon-document-add: unquote('"\\e91b"');
$icon-document: unquote('"\\e91c"');
$icon-eye: unquote('"\\e91e"');
$icon-file-download: unquote('"\\e920"');
$icon-filter: unquote('"\\e921"');
$icon-graph: unquote('"\\e926"');
$icon-history: unquote('"\\e927"');
$icon-info-circle: unquote('"\\e928"');
$icon-key: unquote('"\\e929"');
$icon-logout: unquote('"\\e92a"');
$icon-maximize-square: unquote('"\\e92b"');
$icon-menu: unquote('"\\e92c"');
$icon-move: unquote('"\\e92d"');
$icon-pen-square: unquote('"\\e92e"');
$icon-refresh: unquote('"\\e930"');
$icon-search: unquote('"\\e932"');
$icon-settings: unquote('"\\e933"');
$icon-trash: unquote('"\\e918"');
$icon-user-plus: unquote('"\\e905"');
$icon-medical-kit: unquote('"\\e956"');
$icon-doc-add: unquote('"\\e901"');
$icon-time: unquote('"\\e903"');
$icon-revote: unquote('"\\e904"');
$icon-docs: unquote('"\\e906"');
$icon-users-group: unquote('"\\e907"');
$icon-notebook: unquote('"\\e923"');
$icon-gadget: unquote('"\\e908"');
$icon-heart-pulse: unquote('"\\e909"');
$icon-hospital: unquote('"\\e931"');
$icon-notification-on: unquote('"\\e938"');
$icon-book-open: unquote('"\\e93a"');
$icon-salamat-logo: unquote('"\\e93b"');
$icon-tamin-logo: unquote('"\\e93c"');
$icon-arrow-diagonal: unquote('"\\e93f"');
$icon-phone: unquote('"\\e93e"');
$icon-close: unquote('"\\e93d"');
$icon-video: unquote('"\\e94b"');
$icon-microphone-mute: unquote('"\\e94a"');
$icon-chevron-left: unquote('"\\e949"');
$icon-minus-circle: unquote('"\\e948"');
$icon-video: unquote('"\\e947"');
$icon-video-close: unquote('"\\e946"');
$icon-attach: unquote('"\\e945"');
$icon-microphone: unquote('"\\e944"');
$icon-profile-2user: unquote('"\\e943"');
$icon-messages: unquote('"\\e942"');
$icon-exit-right: unquote('"\\e941"');
$icon-dots-vertical: unquote('"\\e940"');
$icon-dashboard-admission: unquote('"\\e969"');
$icon-dashboard-capsules: unquote('"\\e968"');
$icon-microscope: unquote('"\\e967"');
$icon-stethoscope: unquote('"\\e94e"');
$icon-alarm: unquote('"\\e94d"');
$icon-user-cross: unquote('"\\e94c"');
$icon-color-swatch: unquote('"\\e94b"');
$icon-chat-close: unquote('"\\e94f"');
$icon-clipboard-check: unquote('"\\e951"');
$icon-home: unquote('"\\e950"');
$icon-ai: unquote('"\\e953"');
$icon-share: unquote('"\\e952"');
$icon-mailbox: unquote('"\\e954"');
$icon-screenshare: unquote('"\\e955"');
